export interface IProductionStandardTime {
    id: string;
    productId: string;
    minimumQuantity: number;
    fixedValue: number;
    variableValue: number;
    miseEnPlace: number;
}

export interface IProductionStandardTimeRES {
    succeeded: boolean;
    productionStandardTime: IProductionStandardTime;
}

export const EMPTY_PRODUCTION_STANDARD_TIME: IProductionStandardTime = {
    id: "",
    productId: "",
    minimumQuantity: 0,
    fixedValue: 0,
    variableValue: 0,
    miseEnPlace: 0
};

export function isProductionStandardTime (obj: any): boolean {
    const isId = obj.id !== undefined && obj.id !== null;
    const isProductId = obj.productId !== undefined && obj.productId !== null;
    const isMinimumQuantity = obj.minimumQuantity !== undefined && obj.minimumQuantity !== null;
    const isFixedValue = obj.fixedValue !== undefined && obj.fixedValue !== null;
    const isVariableValue = obj.variableValue !== undefined && obj.variableValue !== null;
    const isMiseEnPlace = obj.miseEnPlace !== undefined && obj.miseEnPlace !== null;

    const ret = isId && isProductId && isMinimumQuantity && isFixedValue && isVariableValue && isMiseEnPlace;

    return ret;
}
