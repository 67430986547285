import { IAllergen } from "./allergen";

export interface IIngredient {
    id: string;
    name: string;
    unit: string;
    storageCondition: string;
    category: string;
    pdfTechnicalSheetHash: string;
    pdfInfoPosterHash: string;
    imgHash?: string | null;
    videoHash?: string | OnBeforeUnloadEventHandlerNonNull;
    kj: number;
    kcal: number;
    fats: number;
    saturatedFats: number;
    carbohydrates: number;
    sugars: number;
    protein: number;
    salt: number;
    ingredients: string;
    printableTitle: string;
    printableUnit: string;
    printable: boolean;
    valabilityAfterDefrost: string | null;
    valabilityAfterBaking: string | null;
    valabilityAfterSlicing: string | null;
    preparationType: string | null;
    bakingTime: number | null;
    defrostTime: number | null;
    valabilityAfterPackageOpening: string | null;
    fixedWeight: number | null;
}

export const EMPTY_INGREDIENT: IIngredient = {
    id: "",
    name: "",
    unit: "",
    storageCondition: "",
    category: "",
    pdfTechnicalSheetHash: "",
    pdfInfoPosterHash: "",
    kj: 0,
    kcal: 0,
    fats: 0,
    saturatedFats: 0,
    carbohydrates: 0,
    sugars: 0,
    protein: 0,
    salt: 0,
    ingredients: "",
    printableTitle: "",
    printableUnit: "",
    printable: true,
    valabilityAfterDefrost: null,
    valabilityAfterBaking: null,
    valabilityAfterSlicing: null,
    preparationType: null,
    bakingTime: null,
    defrostTime: null,
    valabilityAfterPackageOpening: null,
    fixedWeight: null
}

export interface IIngredientCategory {
    id: string;
    name: string;
}

export interface IIngredientAllergen {
    id: string;
    ingredientId: string;
    allergen: IAllergen;
}

export interface IIngredientAllergenDBQuery {
    id: string;
    ingredientId: string;
    ingredientName: string;
    ingredientUnit: string;
    ingredientStorageCondition: string;
    ingredientCategory: string;
    allergenId: string;
    allergenName: string;
    allergenPrintableName: string;
    allergenState: string;
}

export interface IIngredientDataReq {
    ingredient: IIngredient;
    ingredientAllergens?: IIngredientAllergen[];
    imgHash?: {
        data: string;
        delete: boolean;
    };
    videoHash?: {
        data: string;
        delete: boolean;
    };
    pdfTechnicalSheet?: {
        data: string;
        delete: boolean;
    },
    pdfInfoPoster?: {
        data: string;
        delete: boolean;
    }
}

export interface IIngredientRES {
    succeeded: boolean;
    ingredient: IIngredient;
}

export interface IIngredientDeleteRES {
    succeeded: boolean;
}

export interface IIngredientScrapping {
    id?: string;
    stationId: string;
    operatorId: string;
    scrappingDate: Date;
    ingredientId: string;
    qty: number;
    cause: string;
    batchId?: string;
    preparedBarcode?: string;
}

export interface IIngredientScrappingInfo {
    ingredientId: string;
    ingredientName: string;
    stationId: string;
    scrappingDate: Date;
    qty: number;
    unit: string;
    cause: string;
}

export interface IIngredientScrappingFilter {
    grouping: string;
    date: {
        from: Date;
        until: Date;
    };
    time: {
        start: string;
        end: string;
    };
    ingredient: string;
    timezone: string;
    cause: string;
}

export interface IIngredientScrappingInfoCSVEntry {
    ingredientId: string;
    ingredientName: string;
    stationId: string;
    scrappingDate: string;
    scrappingTime: string;
    qty: string;
    unit: string;
    cause: string;
}


export interface IIngredientScrappingInfoCSV extends Array<IIngredientScrappingInfoCSVEntry>{};
