
import Vue from "vue";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import LabelPreviewDialog from "@/components/products/LabelPreviewDialog.vue";
import {
	IProduct,
	IProductDataReq,
	IProductRES,
	EMPTY_PRODUCT,
	IProductCategory
} from "@common/product";
import { EMPTY_RECIPE_INGREDIENT, IRecipeIngredient } from "@common/recipe";
import { IProductionStandardTime, EMPTY_PRODUCTION_STANDARD_TIME } from "@common/productionStandardTime";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import router from "@/router";
import { IAllergen } from "@common/allergen";
import { IIngredient } from "@common/ingredient";

export default Vue.extend({
	name: "ProductAdd",
	components: {
		"label-preview-dialog": LabelPreviewDialog
	},
	data: () => ({
		process: false as boolean,
		product: EMPTY_PRODUCT as IProduct,
		recipe: [] as IRecipeIngredient[],
		pdf: {
			data: "",
			delete: false
		},
		img: {
			data: "",
			delete: false
		},
		video: {
			data: "",
			delete: false
		},
		allergens: [] as IAllergen[],
		productCategories: [] as IProductCategory[],
		ingredients: [] as IIngredient[],
		productionStandardTime: EMPTY_PRODUCTION_STANDARD_TIME as IProductionStandardTime,
		productPriorityRules: [
			(value: string) => !!value || "Required.",
			(value: string) => (parseInt(value) >= 0) || "The priority must be a number grater than 0."
		]
	}),
	created: async function () {
		this.store.dispatch.changeAppTitle("Add new product");

		if (this.store.getters.ingredients.length === 0) {
			await this.store.dispatch.fetchIngredients();
		}

		this.ingredients = JSON.parse(JSON.stringify(this.store.getters.ingredients));
		this.ingredients.map((ingredient) => {
			ingredient.name = `(${ingredient.id}) ${ingredient.name}`;

			return ingredient;
		});

		if (this.store.getters.productCategories.length === 0) {
			await this.store.dispatch.fetchProductCategories();
		}

		if (this.store.getters.productionStandardTimes.length === 0) {
			await this.store.dispatch.fetchProductionStandardTimes();
		}

		this.productCategories = JSON.parse(JSON.stringify(this.store.getters.productCategories));
		this.productCategories.map((category) => {
			category.name = `(${category.id}) ${category.name}`;

			return category;
		});

		this.product = { ...EMPTY_PRODUCT };

		if (this.store.getters.ingredients.length !== 0) {
			// initial recipe (display purpose only)
			this.addNewIngredient();
		}
	},
	computed: {
		ingredientInfos () {
			return (recipeIngredient: IRecipeIngredient) => {
				return this.store.getters.ingredients.find((ingredient) => recipeIngredient.ingredientId === ingredient.id);
			};
		}
	},
	methods: {
		addNewIngredient () {
			const newRecipeIngredient: IRecipeIngredient = {
				id: EMPTY_RECIPE_INGREDIENT.id,
				productId: this.product.id,
				ingredientId: EMPTY_RECIPE_INGREDIENT.ingredientId,
				quantity: EMPTY_RECIPE_INGREDIENT.quantity,
				printableQuantity: EMPTY_RECIPE_INGREDIENT.printableQuantity,
				position: EMPTY_RECIPE_INGREDIENT.position
			};

			if (this.store.getters.ingredients.length) {
				newRecipeIngredient.ingredientId = this.store.getters.ingredients[0].id;
			}

			this.recipe.push(newRecipeIngredient);
		},
		deleteIngredient (ingredientIdx: number) {
			this.recipe.splice(ingredientIdx, 1);
		},
		async saveProduct () {
			this.process = true;
			try {
				const data: IProductDataReq = {
					product: this.product,
					recipe: this.recipe,
					pdf: this.pdf,
					img: this.img,
					video: this.video
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/product/add`,
				};
				const res: AxiosResponse<IServerRES<IProductRES>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					const product: IProduct = res.data.payload.product;
					for (const category of this.store.getters.productCategories) {
						if (category.id === product.categoryId) {
							product.categoryName = category.name;
						}
					}
					this.store.dispatch.addProduct(product);
				} else {
					// TODO ERROR DISPLAY
					console.error(new Error(res.data.payload.message));
				}
			} catch (err) {
				// TODO ERROR DISPLAY
				console.error(err);
			}
			try {
				const data: IProductionStandardTime = {
					productId: this.product.id,
					id: this.product.id,
					minimumQuantity: this.productionStandardTime.minimumQuantity,
					fixedValue: this.productionStandardTime.fixedValue,
					variableValue: this.productionStandardTime.variableValue,
					miseEnPlace: 0
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/production-standard-time/add`,
				};
				const res: AxiosResponse<IServerRES<IProductionStandardTime>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					const newProductionStandardTime = res.data.payload;
					this.store.dispatch.addProductionStandardTime(newProductionStandardTime);
				} else {
					console.error(new Error(res.data.payload.message));
				}
			} catch (err) {
				console.error(err);
			}

			router.push({ path: "/products" });
		},
		async fetchRecipeAllergens () {
			try {
				const data: IRecipeIngredient[] = this.recipe;
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/product/recipe/allergens`,
				};
				const res: AxiosResponse<IServerRES<IAllergen[]>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					this.allergens = res.data.payload;
				} else {
					// TODO ERROR DISPLAY
					console.error(new Error(res.data.payload.message));
				}
			} catch (err) {
				// TODO ERROR DISPLAY
				console.error(err);
			}
		},
		fileChange (e: Event) {
			const target = e.target as HTMLInputElement;
			if (target.files !== null &&
							target.files.length) {
				switch (target.id) {
					case ("img_file"):
						if (target.files[0]) {
							const imgReader: FileReader = new FileReader();
							imgReader.readAsDataURL(target.files[0]);
							imgReader.onload = async () => {
								const result: string|undefined = imgReader.result?.toString().split(",")[1];
								if (result) {
									this.img.data = result;
								}
							};
						}
						break;
					case ("pdf_file"):
						if (target.files[0]) {
							const pdfReader: FileReader = new FileReader();
							pdfReader.readAsDataURL(target.files[0]);
							pdfReader.onload = async () => {
								const result: string|undefined = pdfReader.result?.toString().split(",")[1];
								if (result) {
									this.pdf.data = result;
								}
							};
						}
						break;
					case ("video_file"):
						if (target.files[0]) {
							const videoReader: FileReader = new FileReader();
							videoReader.readAsDataURL(target.files[0]);
							videoReader.onload = async () => {
								const result: string|undefined = videoReader.result?.toString().split(",")[1];
								if (result) {
									this.video.data = result;
								}
							};
						}
						break;
				}
			} else if (target.files !== null &&
							target.files.length === 0) {
				switch (target.id) {
					case ("img_file"):
						this.img.data = "";
						break;
					case ("pdf_file"):
						this.pdf.data = "";
						break;
					case ("video_file"):
						this.video.data = "";
						break;
				}
			}
		},
		idChange () {
			this.recipe.map((ingredient) => {
				ingredient.productId = this.product.id;

				return ingredient;
			});
		},
		async previewLabel () {
			try {
				await this.fetchRecipeAllergens();
				await (this.$refs.labelPreview as InstanceType<typeof LabelPreviewDialog>).open(this.product, this.recipe, this.allergens);
			} catch (err) {
				console.error(err);
			}
		}
	}
});
