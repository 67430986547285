export interface IRecipeIngredient {
    id: string;
    productId: string;
    ingredientId: string;
    quantity: number;
    printableQuantity: number;
    position?: number;
}

export const EMPTY_RECIPE_INGREDIENT: IRecipeIngredient = {
    id: "",
    productId: "",
    ingredientId: "",
    quantity: 0,
    printableQuantity: 0,
    position: 0
}
