import { IRecipeIngredient } from "./recipe"

export interface IProduct {
    id: string;
    idTG: string;
    title: string;
    printableTitle: string;
    dateModified: string,
    categoryId: string;
    categoryName?: string;
    pdfHash: string;
    imgHash: string;
    videoHash: string;
    shelfLife: string;
    weight: number;
    minimumStorageTemperature: number;
    maximumStorageTemperature: number;
    kj: number;
    kcal: number;
    fats: number;
    saturatedFats: number;
    carbohydrates: number;
    sugars: number;
    protein: number;
    salt: number;
    priority?: number;
}

export interface IProductCategory {
    id: string;
    name: string;
}

export const EMPTY_PRODUCT: IProduct = {
    id: "",
    idTG: "",
    title: "",
    printableTitle: "",
    dateModified: "",
    categoryId: "",
    pdfHash: "",
    imgHash: "",
    videoHash: "",
    shelfLife: "",
    weight: 0,
    minimumStorageTemperature: 0,
    maximumStorageTemperature: 0,
    kj: 0,
    kcal: 0,
    fats: 0,
    saturatedFats: 0,
    carbohydrates: 0,
    sugars: 0,
    protein: 0,
    salt: 0
}

export interface IProductDataReq {
    product: IProduct;
    pdf: {
        data: string;
        delete: boolean;
    };
    img: {
        data: string;
        delete: boolean;
    };
    video: {
        data: string;
        delete: boolean;
    };
    recipe: IRecipeIngredient[];
    deletedIngredients?: IRecipeIngredient[];
}

export interface IProductRES {
    fileStatus: string;
    product: IProduct
}

export interface IProductDeleteRES {
    succeeded: boolean;
}

export interface IProductProduction {
    id?: string;
    productId: string;
    productTitle?: string;
	stationId: string;
    productionDate: Date;
    productionOperatorId: string;
    productionOperatorName?: string;
}

export interface IProductProductionFilter {
    date: {
        from: Date;
        until: Date;
    }
}

export interface IProductStat extends IProductProduction {
	soldDate: Date | null;
	soldId: string | null;
    scrappingDate: Date | null;
    scrappingOperatorId: string | null;
	cause: string | null;
    barcode: string | null;
}

export interface IProductStatFilter {
    date: {
        from: Date;
        until: Date;
    }
}

export interface IProductStatBarcodeFilter {
    barcode: string;
}

export interface IProductStatSalesInfo {
    productId: string;
    productName: string;
	stationId: string;
    productionDate: Date;
	soldDate: Date;
    soldType: string;
    soldId?: string;
    qty: number;
}

export interface IProductStatSalesFilter {
    grouping: string;
    soldType: string;
    filterBy: string;
    date: {
        from: Date;
        until: Date;
    };
    time: {
        start: string;
        end: string;
    };
    product: string;
    timezone: string;
}

export interface IProductStatScrappingInfo {
    productId: string;
    productName: string;
	stationId: string;
    productionDate: Date;
	scrappingDate: Date;
    scrappingOperatorId?: string;
    scrappingOperatorName?: string;
    qty: number;
	cause: string | null;
}

export interface IProductStatSalesInfoCSVEntry {
    productId: string;
    productName: string;
	stationId: string;
    productionDate: string;
    productionTime: string;
	soldDate: string;
	soldTime: string;
    soldType: string
    qty: string;
}

export interface IProductStatSalesInfoCSV extends Array<IProductStatSalesInfoCSVEntry>{};

export interface IProductStatScrappingFilter {
    grouping: string;
    filterBy: string;
    date: {
        from: Date;
        until: Date;
    };
    time: {
        start: string;
        end: string;
    };
    product: string;
    timezone: string;
    cause: string;
}

export interface IProductStatScrappingInfoCSVEntry {
    productId: string;
    productName: string;
	stationId: string;
    productionDate: string;
    productionTime: string;
	scrappingDate: string;
	scrappingTime: string;
    qty: string;
	cause: string | null;
}

export interface IProductStatScrappingInfoCSV extends Array<IProductStatScrappingInfoCSVEntry>{};

export interface IProductProductionStats {
    barcode: string;
    productId: string;
    productName?: string;
    dateProduction: Date;
    dateExpiration: Date;
    operatorId: string;
    operatorName?: string;
    stationId: string;
    qty: number;
}

export function isProductProductionStats (obj: any): boolean {
    const isBarcode = obj.barcode !== undefined && obj.barcode !== null;
    const isProductId = obj.productId !== undefined && obj.productId !== null;
    const isDateProduction = obj.dateProduction !== undefined && obj.dateProduction !== null;
    const isDateExpiration = obj.dateExpiration !== undefined && obj.dateExpiration !== null;
    const isStationId = obj.stationId !== undefined && obj.stationId !== null;
    const isOperatorID = obj.operatorId !== undefined && obj.operatorId !== null;
    const isQty = obj.qty !== undefined && obj.qty !== null;

    const ret = isBarcode && isProductId && isDateProduction && isDateExpiration
                    && isStationId && isOperatorID && isQty;

    return ret;
}

export interface IProductProductionStatsFilter extends IProductStatFilter {
    product: string;
};

export interface IProductProductionIngredient {
    id?: string;
    barcodeProduct: string;
    barcodeIngredient: string | null;
    barcodeFrying: string | null;
    barcodeBaking: string | null;
    barcodeDefrost: string | null;
    stationId: string;
}

export function isProductProductionIngredient (obj: any): boolean {
    const isBarcodeProduct = obj.barcodeProduct !== undefined && obj.barcodeProduct !== null;
    const isBarcodeIngredient = obj.barcodeIngredient !== undefined;
    const isBarcodeFrying = obj.barcodeFrying !== undefined;
    const isBarcodeBaking = obj.barcodeBaking !== undefined;
    const isBarcodeDefrost = obj.barcodeDefrost !== undefined;
    const isStationId = obj.barcodeProduct !== undefined && obj.stationId !== null;

    const isValidIngredient = obj.barcodeIngredient === null || obj.barcodeFrying === null;

    const ret = isBarcodeProduct && isBarcodeIngredient && isBarcodeFrying && isStationId
            && isValidIngredient && isBarcodeBaking && isBarcodeDefrost;

    return ret;
}

export interface IProductProductionIngredientsInfoFilter {
    barcode: string
}

export interface IProductProductionStatsCSVEntry {
    barcode: string;
    productId: string;
    productName: string;
    productionDate: string;
    productionTime: string;
    operatorName: string;
    stationId: string;
    qty: string;
}

export interface IProductProductionStatsCSV extends Array<IProductProductionStatsCSVEntry>{};

export interface IProductStatCumulated {
    stationId: string;
    productId: string;
    qty: number;
}

export interface IProductsStatCumulated extends Array<IProductStatCumulated>{};

export interface IProductDailyOverview {
    stationId: string;
    productId: string;
    productionQty: number;
    salesQty: number;
    scrappingQty: number;
    predictionQty: number;
    date: Date;
}

export interface IProductDailyOverviews extends Array<IProductDailyOverview>{};

export interface IProductDailyOverviewFilter {
    date: {
        start: Date;
        end: Date;
    };
    stationId: string;
    productId: string;
    timezone: string;
}

export interface  IProductDailyOverviewCSVEntry extends Omit<IProductDailyOverview, 'date'> {
    date: string;
    productName: string;
    errorPercentage: number;
};
export interface IProductDailyOverviewCSV extends Array<IProductDailyOverviewCSVEntry>{};
