
import Vue from "vue";
import { EMPTY_PRODUCT, IProduct } from "@common/product";
import { IRecipeIngredient } from "@common/recipe";
import { IAllergen } from "@common/allergen";
import { EMPTY_INGREDIENT, IIngredient } from "@common/ingredient";

type ResolveFunction = (value: boolean) => void;
type RejectFunction = (value: Error) => void;

export default Vue.extend({
	name: "LabelPreviewDialog",
	data: () => ({
		dialog: false as boolean,
		resolve: null as ResolveFunction|null,
		reject: null as RejectFunction|null,
		message: "" as string,
		product: EMPTY_PRODUCT as IProduct,
		recipe: [] as IRecipeIngredient[],
		allergens: [] as IAllergen[]
	}),
	computed: {
		labelStorageExpirationInfo () {
			const shelfLife = this.product.shelfLife.replace("h", "");
			let minimumStorageTemperature = "";
			if (this.product.minimumStorageTemperature > 0) {
				minimumStorageTemperature = `+${this.product.minimumStorageTemperature}`;
			} else {
				minimumStorageTemperature = this.product.minimumStorageTemperature.toString();
			}
			let maximumStorageTemperature = "";
			if (this.product.maximumStorageTemperature > 0) {
				maximumStorageTemperature = `+${this.product.maximumStorageTemperature}`;
			} else {
				maximumStorageTemperature = this.product.maximumStorageTemperature.toString();
			}

			return `(${shelfLife} ore de la momentul producței; a se pastra la temperaturi cuprinse între ${minimumStorageTemperature}°C și ${maximumStorageTemperature}°C)`;
		},
		labelIngredients () {
			const labelIngredientsArr = [];

			for (const ingredientRecipe of this.recipe) {
				const ingredient: IIngredient = this.store.getters.ingredients.find(ingredient => ingredient.id === ingredientRecipe.ingredientId) || EMPTY_INGREDIENT;

				if (ingredient) {
					if (ingredient.printable) {
						let col1 = `- ${ingredient.printableTitle}`;
						if (ingredient.storageCondition === "Congelat") {
							col1 += "*";
						}

						let col2 = "";
						if (ingredient.printableUnit === "kg") {
							col2 = `${ingredientRecipe.printableQuantity * 1000} g`;
						} else {
							col2 = `${ingredientRecipe.printableQuantity} ${ingredient.printableUnit}`;
						}

						labelIngredientsArr.push({
							col1,
							col2
						});
					}
				}
			}

			return labelIngredientsArr;
		},
		labelAllergens () {
			const yesArr = [];
			const maybeArr = [];

			for (const allergen of this.allergens) {
				if (allergen.state === "Yes") {
					yesArr.push(allergen.printableName);
				} else if (allergen.state === "Maybe") {
					maybeArr.push(allergen.printableName);
				}
			}

			let str = `Produsul conține subsțante ALERGENE: ${yesArr.join(", ")}. `;
			if (maybeArr.length) {
				str += `Poate conține urme de: ${maybeArr.join(", ")}.`;
			}

			return str;
		},
		labelNutritionalInfos () {
			return `Informații nutriționale 100g: ${this.product.kj}kJ / ${this.product.kcal}kcal, Grăsimi ${this.product.fats}g (din care acizi grași saturați ${this.product.saturatedFats}g), Glucide ${this.product.carbohydrates}g (din care zaharuri ${this.product.sugars}g), Proteine ${this.product.protein}g, Sare ${this.product.salt}g.`;
		}
	},
	methods: {
		open (product: IProduct, recipe: IRecipeIngredient[], allergens: IAllergen[]) {
			this.dialog = true;
			this.product = product;
			this.recipe = JSON.parse(JSON.stringify(recipe));
			this.recipe.sort((a, b) => b.printableQuantity - a.printableQuantity);
			this.allergens = allergens;

			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},
		clearPromise () {
			this.resolve = null;
			this.reject = null;
		},
		agree () {
			if (this.resolve) {
				this.resolve(true);
			}
			this.dialog = false;
			this.clearPromise();
		},
	},
});
