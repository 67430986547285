import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":800},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary","dense":"","flat":""}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("Product Label Preview")])],1),_c(VCardText,{staticClass:"pa-4 text-subtitle-2"},[_c(VContainer,[_c(VRow,[_c('h1',[_vm._v(_vm._s(_vm.product.printableTitle))])]),_c(VRow,{staticClass:"mt-4"},[_c('h2',[_vm._v("Cantitate neta: "+_vm._s(_vm.product.weight)+"g")])]),_c(VRow,[_c(VCol,[_c('h2',[_vm._v("Ingrediente:")]),_c(VContainer,{attrs:{"id":"ingredients"}},_vm._l((_vm.labelIngredients),function(ingredientText,index){return _c(VRow,{key:index,staticClass:"mt-n2",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"7"}},[_vm._v(" "+_vm._s(ingredientText.col1)+" ")]),_c(VCol,{staticClass:"text-right",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(ingredientText.col2)+" ")])],1)}),1),_c('p',[_vm._v("Expiră la (ziua/luna) ............................................. ora............................"),_c('br'),_vm._v(_vm._s(_vm.labelStorageExpirationInfo))]),_c('p',[_vm._v(_vm._s(_vm.labelAllergens))]),_c('p',{staticClass:"mt-n4"},[_vm._v(_vm._s(_vm.labelNutritionalInfos))]),_c('p',{staticClass:"mt-n3"},[_vm._v("* Produs/din produs decongelat.")])],1)],1)],1)],1),_c(VCardActions,{staticClass:"pt-0"},[_c(VSpacer),_c(VBtn,{attrs:{"depressed":"","color":"primary"},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("check")]),_vm._v("Ok ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }